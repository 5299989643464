export default {
  data() {
    return { timer: null };
  },
  mounted() {
    this.checkTimer(5, this.title);
  },
  computed: {
    timerFormat() {
      let hours = null;
      let minutes = null;
      let seconds = null;

      hours = Math.floor(this.timer / 3600);
      minutes = Math.floor(this.timer / 60);
      seconds = this.timer - hours * 3600 - minutes * 60;

      if (hours < 10) hours = `0${hours}`;
      if (minutes < 10) minutes = `0${minutes}`;
      if (seconds < 10) seconds = `0${seconds}`;

      if (this.isHour) return `${hours}:${minutes}:${seconds}`;
      else return `${minutes}:${seconds}`;
    },
  },
  methods: {
    setTimer(name, minutes) {
      localStorage.setItem(`${name}-timer`, Date.now());

      this.timer = minutes * 60;

      this.handleTimer(name);
    },
    handleTimer(name) {
      let id = setInterval(() => {
        if (this.timer === 0) {
          this.timer = null;
          localStorage.removeItem(`${name}-timer`);
          clearInterval(id);
        } else {
          this.timer--;
        }
      }, 1000);
    },
    checkTimer(minutes, name) {
      let timer = localStorage.getItem(`${name}-timer`);
      if (timer === null) return;

      let secondTick = 60;
      if (Date.now() - timer > secondTick * 1000) {
        localStorage.removeItem("`${name}-timer`");
      } else {
        this.timer = (
          minutes * secondTick -
          (Date.now() - timer) / 1000
        ).toFixed(0);
        this.handleTimer(name);
      }
    },
  },
};
