<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
       <div class="col-12 col-md-auto">
      <h1>{{ $t("profile.title") }} </h1>
    </div>
    </div>
    <profile-avatar :fullName="true" left />

    <ul class="tabs mt-4 max-660 _mob100">
      <li @click="setTab('personal')">
        <button :class="{ active: currentTab === 'personal' }">
          <svg size="0 0 14 14" v-svg symbol="email" />
          {{ $t("profile.personal_data") }}
        </button>
      </li>
      <li @click="setTab('payment')">
        <button :class="{ active: currentTab === 'payment' }">
          <svg size="0 0 14 14" v-svg symbol="card-2" />
          {{ $t("profile.payment_requisites") }}
        </button>
      </li>
      <li @click="setTab('password')">
        <button :class="{ active: currentTab === 'password' }">
          <svg size="0 0 14 14" v-svg symbol="key" />
          {{ $t("profile.password") }}
        </button>
      </li>
    </ul>

    <div
      class="tubs-content__item active"
      :class="currentTab === 'payment' ? 'max-660-1620' : 'max-660'"
    >
      <component :is="currentTabComponent"></component>
    </div>
  </div>
</template>

<script>
import PanelService from "@/api/servicePanel";
import ProfileAvatar from "@/components/ProfileAvatar.vue";
import PersonalFields from "@/components/Profile/PersonalFields.vue";
import PasswordFields from "@/components/Profile/PasswordFields.vue";
import PaymentFields from "@/components/Profile/NewPaymentFields.vue";

export default {
  beforeMount() {
    document.title = `Partners.House | ${this.$t("profile.title")}`;
  },
  beforeUpdate() {
    document.title = `Partners.House | ${this.$t("profile.title")}`;
  },
  components: { ProfileAvatar, PersonalFields, PasswordFields, PaymentFields },
  data() {
    return {
      currentTab: "personal",
      fullName: "",
      direction: "webmaster",
      name: "",
    };
  },
  mounted() {
    PanelService.getUserData().then((res) => {
      if (res && res.status === 200) {
        this.email = res.data.email;
        this.telegram = res.data.telegram;
        this.skype = res.data.skype;
      }
    });
  },
  computed: {
    currentTabComponent() {
      return this.currentTab + "Fields";
    },
  },
  methods: {
    setTab(value) {
      this.currentTab = value;
    },
  },
};
</script>
 