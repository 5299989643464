<template>
  <div class="row pr-2 pl-2">
    <div class="col-12">
      <h2>{{ $t("profile.payment_requisites") }}</h2>
      <p>{{ $t("finances.add_requisites") }}</p>
    </div>

    <template v-if="showContent">
      <template v-for="(value, key) of paymentsList">
        <PaymentBlock :title="value" :value="getData(value)" :limitVerification="vefiriedPayment.find((el) => el.type === value).limitVerification" :isVerified="vefiriedPayment.find((el) => el.type === value).isVerified" :key="key" :error="$v[value]" @update-value="updateData(value, $event)" @refresh-wallets="getWallets" />
      </template>
    </template>
  </div>
</template>
<script>
import PaymentBlock from "./PaymentBlock.vue";
import { validationMixin } from "vuelidate";
import PanelService from "@/api/servicePanel";
import { email, helpers, maxLength, minLength } from "vuelidate/lib/validators";

const webMoneyRegex = helpers.regex("webmoney", /^Z[0-9]*$/);
const capitalistRegex = helpers.regex("capitalist", /^U[0-9]*$/);
const cardRegex = helpers.regex("card", /[0-9]*$/);
// const qiwiRegex = helpers.regex("qiwi", /[0-9]*$/);
const usdtRegex = helpers.regex("usdt", /^T[a-zA-Z[0-9]*$/);
const pay2HouseRegex = helpers.regex("pay2house", /^P2U[0-9]{8}$/);

export default {
  name: "PaymantsFields",
  components: {
    PaymentBlock,
  },
  mixins: [validationMixin],
  validations: {
    ph: {
      email,
    },
    webmoney: {
      webMoneyRegex,
      minLength: minLength(13),
      maxLength: maxLength(13),
    },
    capitalist: {
      capitalistRegex,
      minLength: minLength(7),
      maxLength: maxLength(9),
    },
    card: {
      cardRegex,
      minLength: minLength(16),
      maxLength: maxLength(16),
    },
    cardRu: {
      cardRegex,
      minLength: minLength(16),
      maxLength: maxLength(16),
    },
    cardKz: {
      cardRegex,
      minLength: minLength(16),
      maxLength: maxLength(16),
    },
    // qiwi: {
    //   qiwiRegex,
    //   minLength: minLength(11),
    //   maxLength: maxLength(11),
    // },
    yandex: {
      minLength: minLength(0),
    },
    usdt: {
      usdtRegex,
      minLength: minLength(34),
      maxLength: maxLength(34),
    },
    payoneer: {
      email,
    },
    pay2House: {
      pay2HouseRegex,
      minLength: minLength(11),
      maxLength: maxLength(11),
    },
  },
  data() {
    return {
      ph: "",
      pay2House: "",
      capitalist: "",
      usdt: "",
      payoneer: "",
      webmoney: "",
      card: "",
      // cardRu: "",
      cardKz: "",
      // yandex: "",
      // qiwi: "",
      vefiriedPayment: [],
      showContent: false,
      paymentsList: [
        "ph",
        "pay2House",
        "capitalist",
        "usdt",
        "payoneer",
        "webmoney",
        "card",
        // "cardRu",
        "cardKz",
        // "yandex",
        // "qiwi",
      ],
    };
  },
  mounted() {
    this.paymentsList.forEach((el) => {
      this.vefiriedPayment.push({
        type: el,
        isVerified: true,
        limitVerification: false,
      });
    });
    this.getWallets();
  },
  methods: {
    getData(key) {
      return this[key];
    },
    getWallets() {
      PanelService.getWallets()
        .then((res) => {
          if (res && res.status === 200) {
            this.ph = "";
            this.pay2House = "";
            this.capitalist = "";
            this.usdt = "";
            this.webmoney = "";
            this.card = "";
            this.cardRu = "";
            this.payoneer = "";
            (this.cardKz = ""), (this.yandex = "");
            // this.qiwi = "";
            if (res.data.length) {
              res.data.forEach((element) => {
                this[element.type] = element.wallet;
                this.vefiriedPayment.forEach((el) => {
                  if (el.type === element.type) {
                    el.isVerified = el.isVerified && element.is_verified;
                    el.limitVerification = element.limit_email_verification;
                  }
                });
              });
            }
          }
        })
        .finally(() => {
          this.showContent = true;
        });
    },
    updateData(key, value) {
      this[key] = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  gap: 15px 0px;

  @media (max-width: 1200px) {
    padding-bottom: 60px;
  }
}
</style>
